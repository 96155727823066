<template>
	<div class="text-center m-4" v-if="loading">
		<b-spinner variant="primary" label="Spinning"></b-spinner>
	</div>
	<div class="add-user-form" v-else>
		<form @submit.prevent="onSubmit">
			<h3>Add New User:</h3>

			<div class="form-group" v-if="getUserIsAdministrator">
				<div class="custom-control custom-switch">
					<input type="checkbox" class="custom-control-input" value="1" v-model="form.isClientAdmin" id="userIsClientAdminSwitch">
					<label class="custom-control-label" for="userIsClientAdminSwitch">User Is Client Admin?</label>
				</div>
			</div>

			<div class="form-group">
				<input required placeholder="Name" v-model="form.name" class="form-control form-control-lg" />
			</div>

			<div class="form-group">
				<input required placeholder="Email" type="email" class="form-control form-control-lg" v-model="form.email" />
			</div>

			<div class="form-group">
				<input type="tel" placeholder="Phone Number (ex. XXX-XXX-XXXX)" class="form-control form-control-lg" v-model="form.phoneNumber" />
			</div>

			<template v-if="getUserIsAdministrator">
				<div class="form-group" v-if="!Array.isArray(options) || options.length == 1">
					<p>You have not added any client instances, please add a client instance to continue.</p>
				</div>
				<div class="form-group" v-else>
					<b-form-select class="styled-select" v-model="form.clientID" size="lg" :options="options"></b-form-select>
				</div>
			</template>

			<button class="btn-blue w-100" @click.prevent="onSubmit">
				Create User
			</button>
		</form>
	</div>
</template>

<script>
	import { mapGetters } from "vuex";
	import router from '../../../router';

	export default {
		data() {
			return {
				loading: false,
				form: {
					isClientAdmin: false,
					name: '',
					email: '',
					clientID: null,
					code: ''
				},
				options: [
					{ value: null, text: 'Please select a client instance' },
				],
				errors: [],
				isSubmitting: false,
			};
		},
		computed: { ...mapGetters(['getClients', 'getUserIsAdministrator']) },
		async mounted() {
			if (this.getUserIsAdministrator) {
				// obliterate client context NOW
				try {
					await this.$store.commit('setResetClientInContext');
				} catch {
					this.$router.replace({ name: 'Home' }).catch(() => {});
				}
			}
			this.$bus.$emit('breadcrumbData', [
				{
					text: 'Account Settings',
					to: {
						name: 'UserManagement',
					}
				}, {
					text: 'AddUser',
					to: {
						name: 'AddUser',
					}
				}
			]);
			// note: nly admins shoud be able to be routed to this page.
			// TODO: MOVE THIS FROM ADMIN QUERIES OUT TO AN ADMIN API CALL FOR BETTER SECURITY
			this.loading = true;
			this.form = {
				name: '',
				email: '',
				phoneNumber: '',
				clientID: null,
				code: ''
			};
			this.isSubmitting = false;

			if (this.getUserIsAdministrator) {
				await this.$store.dispatch('getClientInstances');
				let clients = this.getClients;
				if (Array.isArray(clients) && clients.length > 0) {
					for (let i in clients) {
						this.options.push({
							value: this.getClients[i].client_id,
							text: this.getClients[i].client_name
						});
					}
				}
			}
			this.loading = false;
		},
		methods: {
			async onSubmit() {
				if (this.isSubmitting == (this.isSubmitting = true)) {
					return false;
				}
				this.loading = true;
				try {
					const { email, name, phoneNumber, clientID } = this.form;
					await this.validator(email.toLowerCase(), phoneNumber);
					if (this.errors.length < 0 || this.errors.length == []) {
						let created = await this.$store.dispatch('addUser', {
							email: email.toLowerCase(),
							name: name,
							phoneNumber: phoneNumber,
							clientID: this.getUserIsAdministrator ? clientID : undefined,
							isClientAdmin: this.getUserIsAdministrator ? this.form.isClientAdmin : false
						});
						if (created) {
							// TODO: proper nav back to user management page (that needs breadcrumbing).
							router.go(-1);
						}
					}

				} catch (e) {
					this.$store.dispatch('createErrors', e.message);
				} finally {
					this.isSubmitting = false;
					this.loading = false;
				}
			},
			async validator(email, phoneNumber) {
				let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				if (!email || email == '' || !re.test(email)) {
					await this.$store.dispatch('createErrors', 'Please enter a valid e-mail');
				}

				re = /^\+?1?\s*?\(?\d{3}(?:\)|[-|\s])?\s*?\d{3}[-|\s]?\d{4}$/;
				if (phoneNumber && !re.test(phoneNumber)) {
					await this.$store.dispatch('createErrors', 'Valid phone number (XXX-XXX-XXXX)');
				}
			},
		},
	};
</script>
